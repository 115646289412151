import { Inject, Injectable } from '@angular/core';

import { Observable, of as observableOf } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { NbAuthStrategy } from '../strategies/auth-strategy';
import { NB_AUTH_STRATEGIES } from '../auth.options';
import { NbAuthResult } from './auth-result';
import { NbTokenService } from './token/token.service';
import { NbAuthSimpleToken, NbAuthToken } from './token/token';
import { ServiceHelper } from '../../common/helpers/service.helper';
import { AUTH_CONSTANTS, ROUTE_CONTANTS } from '../../common/constants/app.constants';
import { AuthHelper } from '../../common/helpers/auth.helper';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { EpoLoginService } from '../../common/services/epo-login.service';
import Swal from 'sweetalert2';
declare var $: any;

/**
 * Common authentication service.
 * Should be used to as an interlayer between UI Components and Auth Strategy.
 */
@Injectable()
export class NbAuthService {
  private logoutTimeout;

  constructor(protected tokenService: NbTokenService,
              @Inject(NB_AUTH_STRATEGIES) protected strategies,
              protected router: Router,
              protected epoLoginService: EpoLoginService) {
  }

  /**
   * Retrieves current authenticated token stored
   * @returns {Observable<any>}
   */
  getToken(): Observable<NbAuthToken> {
    return this.tokenService.get();
  }

  /**
   * Returns true if auth token is present in the token storage
   * @returns {Observable<boolean>}
   */
  isAuthenticated(): Observable<boolean> {
    return this.getToken()
      .pipe(map((token: NbAuthToken) => token.isValid()));
  }

  /**
   * Returns true if valid auth token is present in the token storage.
   * If not, calls the strategy refreshToken, and returns isAuthenticated() if success, false otherwise
   * @returns {Observable<boolean>}
   */
  isAuthenticatedOrRefresh(): Observable<boolean> {
    return this.getToken()
      .pipe(
        switchMap(token => {
        if (token.getValue() && !token.isValid()) {
          return this.refreshToken()
            .pipe(
              switchMap(res => {
                if (res.isSuccess()) {
                  return this.isAuthenticated();
                } else {
                  return observableOf(false);
                }
              }),
            );
        } else {
          return observableOf(token.isValid());
        }
    }));
  }

  /**
   * Returns tokens stream
   * @returns {Observable<NbAuthSimpleToken>}
   */
  onTokenChange(): Observable<NbAuthToken> {
    return this.tokenService.tokenChange();
  }

  /**
   * Returns authentication status stream
   * @returns {Observable<boolean>}
   */
  onAuthenticationChange(): Observable<boolean> {
    return this.onTokenChange()
      .pipe(map((token: NbAuthToken) => token.isValid()));
  }

  /**
   * Authenticates with the selected strategy
   * Stores received token in the token storage
   *
   * Example:
   * authenticate('email', {email: 'email@example.com', password: 'test'})
   *
   * @param strategyName
   * @param data
   * @returns {Observable<NbAuthResult>}
   */
  authenticate(strategyName: string, data?: any): Observable<NbAuthResult> {
    return this.getStrategy(strategyName).authenticate(data)
      .pipe(
        switchMap((result: NbAuthResult) => {
          return this.processResultToken(result);
        }),
        switchMap((result: NbAuthResult) => {
          return observableOf(result);
        })
      );
  }

  showRefreshTokenPopup() {
    const popupTimeout = 120000;
    let closeInSeconds = popupTimeout / 1000;
    const displayText = 'Phiên đăng nhập của bạn sắp hết trong #1 giây. Bạn muốn tiếp tục sử dụng?';
    let timer;

    Swal.fire({
      title: '',
      text: displayText.replace(/#1/, closeInSeconds.toString()),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Tiếp tục',
      cancelButtonText: 'Thoát',
      allowOutsideClick: false,
      timer: popupTimeout
    }).then(value => {
      if (timer) {
        clearInterval(timer);
      }
      if (value.isConfirmed) {
        this.extendSession();
      } else if (value.dismiss) {
        this.forceLogout();
      }
    });

    const self = this;
    timer = setInterval(function() {
      closeInSeconds--;
      if (closeInSeconds < 0) {
        clearInterval(timer);
        self.forceLogout();
      }
      $('div#swal2-content').text(displayText.replace(/#1/, closeInSeconds.toString()));
    }, 1000);
  }

  extendSession(self: this = this) {
    self.refreshToken().subscribe((result: NbAuthResult) => {
      if (result.isSuccess()) {
        self.detectIdleAndLogout();
      } else {
        self.forceLogout();
      }
    }, err => {
      self.clearSession();
      ServiceHelper.infoPopup('Phiên đăng nhập đã đến giới hạn, không thể làm mới phiên, vui lòng đăng nhập lại', 'Đồng ý')
        .then(() => self.router.navigateByUrl(ROUTE_CONTANTS.LOGOUT));
    });
  }

  // https://stackoverflow.com/questions/667555/how-to-detect-idle-time-in-javascript-elegantly
  extendSessionEvents = ['mousemove', 'mousedown', 'touchstart', 'click', 'keydown']

  extendSessionSilently() {
    for (const $event of this.extendSessionEvents) {
      window.addEventListener($event, eventListener);
    }
    window.addEventListener('scroll', eventListener, true);

    const timeout = setTimeout(() => {
      for (const $event of this.extendSessionEvents) {
        window.removeEventListener($event, eventListener);
      }
      window.removeEventListener('scroll', eventListener, true);
      this.showRefreshTokenPopup();
    }, 60000);

    const self = this;
    function eventListener() {
      if (timeout) {
        clearTimeout(timeout);
      }

      for (const $event of self.extendSessionEvents) {
        window.removeEventListener($event, eventListener);
      }
      window.removeEventListener('scroll', eventListener, true);

      self.extendSession(self);
    }
  }

  detectIdleAndLogout() {
    this.stopLogoutTimer();
    if (ServiceHelper.isObjectNotEmpty(localStorage.getItem(AUTH_CONSTANTS.TOKEN))) {
      const expiredDate = moment(+localStorage.getItem(AUTH_CONSTANTS.TOKEN_EXPIRED));
      const expiredMs = moment.duration(expiredDate.diff(moment())).as('milliseconds') - 180000;
      if (expiredMs >= 0) {
        this.logoutTimeout = setTimeout(() => {
          this.extendSessionSilently();
        }, expiredMs);
      } else {
        this.extendSession();
      }
    }
  }

  requestRelogin() {
    this.stopLogoutTimer();
    if (ServiceHelper.isObjectNotEmpty(localStorage.getItem(AUTH_CONSTANTS.TOKEN))) {
      this.extendSession();
    } else {
      this.clearSession()
      ServiceHelper.infoPopup('Hết phiên đăng nhập, vui lòng đăng nhập lại', 'Đồng ý')
                  .then(() => this.router.navigateByUrl(ROUTE_CONTANTS.LOGOUT));
    }
  }
  
  forceLogout() {
    this.clearSession();
    this.router.navigateByUrl(ROUTE_CONTANTS.LOGOUT);
  }

  registerSessionEvent() {
    window.addEventListener('storage', $event => {
      if ($event.key === AUTH_CONSTANTS.TOKEN) {
        if (ServiceHelper.isObjectEmpty($event.newValue)) {
          this.forceLogout();
        } else {
          if (this.router.url === ROUTE_CONTANTS.LOGIN) {
            this.router.navigateByUrl(ROUTE_CONTANTS.LANDING_PAGE);
          } else {
            Swal.close();
          }
          this.detectIdleAndLogout();
        }
      }
    });
  }

  clearSession() {
    this.stopLogoutTimer();
    AuthHelper.clearAllStorage();
    return true;
  }

  stopLogoutTimer() {
    Swal.close();
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }
  }

  /**
   * Registers with the selected strategy
   * Stores received token in the token storage
   *
   * Example:
   * register('email', {email: 'email@example.com', name: 'Some Name', password: 'test'})
   *
   * @param strategyName
   * @param data
   * @returns {Observable<NbAuthResult>}
   */
  register(strategyName: string, data?: any): Observable<NbAuthResult> {
    return this.getStrategy(strategyName).register(data)
      .pipe(
        switchMap((result: NbAuthResult) => {
          return this.processResultToken(result);
        }),
      );
  }

  /**
   * Sign outs with the selected strategy
   * Removes token from the token storage
   *
   * Example:
   * logout('email')
   *
   * @param strategyName
   * @returns {Observable<NbAuthResult>}
   */
  logout(strategyName: string): Observable<NbAuthResult> {
    return this.getStrategy(strategyName).logout()
      .pipe(
        switchMap((result: NbAuthResult) => {
          if (result.isSuccess()) {
            this.tokenService.clear()
              .pipe(map(() => result));
          }
          return observableOf(result);
        }),
      );
  }

  /**
   * Sends forgot password request to the selected strategy
   *
   * Example:
   * requestPassword('email', {email: 'email@example.com'})
   *
   * @param strategyName
   * @param data
   * @returns {Observable<NbAuthResult>}
   */
  requestPassword(strategyName: string, data?: any): Observable<NbAuthResult> {
    return this.getStrategy(strategyName).requestPassword(data);
  }

  /**
   * Tries to reset password with the selected strategy
   *
   * Example:
   * resetPassword('email', {newPassword: 'test'})
   *
   * @param strategyName
   * @param data
   * @returns {Observable<NbAuthResult>}
   */
  resetPassword(strategyName: string, data?: any): Observable<NbAuthResult> {
    return this.getStrategy(strategyName).resetPassword(data);
  }

  /**
   * Sends a refresh token request
   * Stores received token in the token storage
   *
   * Example:
   * refreshToken('email', {token: token})
   *
   * @param {string} strategyName
   * @param data
   * @returns {Observable<NbAuthResult>}
   */
  refreshToken(): Observable<NbAuthResult> {
    return this.epoLoginService.refreshToken()
      .pipe(
        map((res: any) => {
          const token = new NbAuthSimpleToken(res.data.token, 'refresh');
          return new NbAuthResult(true, { body: res }, null, [], null, token)
        }),
        switchMap((result: NbAuthResult) => {
          return this.processResultToken(result);
        }),
        catchError(err => {
          return observableOf(new NbAuthResult(false, err, null, err))
        })
      );
  }

  /**
   * Get registered strategy by name
   *
   * Example:
   * getStrategy('email')
   *
   * @param {string} provider
   * @returns {NbAbstractAuthProvider}
   */
  protected getStrategy(strategyName: string): NbAuthStrategy {
    const found = this.strategies.find((strategy: NbAuthStrategy) => strategy.getName() === strategyName);

    if (!found) {
      throw new TypeError(`There is no Auth Strategy registered under '${strategyName}' name`);
    }

    return found;
  }

  private processResultToken(result: NbAuthResult) {
    if (result.isSuccess() && result.getToken()) {
      const roles: Array<string> = result.getResponse().body.data.listRole;
      // Do not save integration token
      if (roles.includes('Integration')) {
        return observableOf(result);
      }
      this.epoLoginService.setLoginDataResponse(result.getResponse().body);
      return this.tokenService.set(result.getToken())
        .pipe(
          map((token: NbAuthToken) => {
            return result;
          }),
        );
    }

    return observableOf(result);
  }
}
