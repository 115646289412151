<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo" [routerLink]="['/']">
            <!-- <span>Eclaim</span> -->
            <img class="logo-icon" src="assets/images/eclaim-logo.png" alt="logo">
        </a>
    </div>
    <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
    <nb-actions size="small">
        <nb-action>
            <!-- <ngx-app-language></ngx-app-language> -->
        </nb-action>
        <nb-action class="user-action">
            <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.name"
                [picture]="user?.picture">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>