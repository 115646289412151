import { EpoLoginService } from './../../../talentnet-pages/common/services/epo-login.service';
import { AUTH_CONSTANTS } from './../../../talentnet-pages/common/constants/app.constants';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { Subject } from 'rxjs';
import { AppLanguageService } from '../../../talentnet-pages/common/services/app-language.service';
import { ROUTE_CONTANTS } from '../../../talentnet-pages/common/constants/app.constants';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  providers: [DatePipe]
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  currentTheme = 'default';
  userMenu = [
  ];

  expiredTime: string = localStorage.getItem(AUTH_CONSTANTS.TOKEN_EXPIRED);
  FIVE_MINUTES: number = 300_000;
  TEN_MINUTES: number = 600_000;
  myDate = new Date();
  currentDate: String;

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private loginService: EpoLoginService,
    private appLanguageService: AppLanguageService,
    private layoutService: LayoutService,
    private datePipe: DatePipe) {
    this.currentDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.appLanguageService.use(this.appLanguageService.getLanguage());
    this.configUserMenu();
    this.appLanguageService.onLangChange.subscribe(lang => {
      this.appLanguageService.use(lang.lang);
      this.configUserMenu();
    });
  }

  configUserMenu() {
    this.userMenu = [
      { title: this.appLanguageService.instant('HEADER_PAGE.LOGOUT'), link: ROUTE_CONTANTS.LOGOUT },
    ];
  }

  ngOnInit() {
    this.user = {
      name: localStorage.getItem(AUTH_CONSTANTS.EPO_USER_FULLNAME)
    };
    // const expired = moment(this.expiredTime, 'DD-MM-YYYY HH:mm:ss');
    // setInterval(() => {
    //   if (ServiceHelper.isObjectNotEmpty(localStorage.getItem(AUTH_CONSTANTS.TOKEN))) {
    //     const diffInMins: number = expired.diff(moment(new Date()), 'minutes');
    //     if (diffInMins > 0 && diffInMins <= 10) {
    //       ServiceHelper.warningPopup(`Phiên đăng nhập sẽ hết hạn sau ${Math.floor(diffInMins)} phút nữa`);
    //     }
    //     if (diffInMins <= 0) {
    //       this.loginService.requestRelogin();
    //     }
    //   }
    // }, this.FIVE_MINUTES);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
