import { API_URL } from './api.constants';

export const TALENTNET_ROOT_ROUTE = 'talentnet';

export const APP_CONSTANTS = {
    LANGUAGE: 'epo_language',
};

export const APPROVE_LEVEL = {
    LEVEL_A: 'A',
    LEVEL_B: 'B',
    CHARGEABLE: 'CH'
};

class EpoStatus {
    public static DRAFT = 'DRAFT';
    public static WAITING = 'WAITING';
    public static APPROVED = 'APPROVED';
    public static REJECTED = 'REJECTED';
    // public static CANCELED = 'CANCELED';
    public static CLOSED = 'CLOSED';
    public static RECORDED = 'RECORDED';
    public static HALF_DONE = 'HALF_DONE';
    public static PAID = 'PAID';
    public static UNPAID = 'UNPAID';
    public static cancelable = (status) => {
        return status === EpoStatus.DRAFT;
    }

    public static sendForApproveable = (status) => {
        if (status) {
            return status === EpoStatus.DRAFT
                || status === EpoStatus.REJECTED;
            // || status === EpoStatus.CANCELED;
        }
        return false;
    }

    public static isStatusEditable = (status) => {
        if (status) {
            return status === EpoStatus.DRAFT || status === EpoStatus.REJECTED;
        }
        return false;
    }

    public static statusFilterList(): string[] {
        return [
            'DRAFT',
            'WAITING',
            'APPROVED',
            'REJECTED',
            // 'CANCELED',
            'ALL'
        ];
    }
    public static paidStatusFilterList(): string[] {
        return [
            'PAID',
            'UNPAID',
            'ALL'
        ];
    }

    public static invoiceStatusFilterList(): string[] {
        return [
            'DRAFT',
            'WAITING',
            'REJECTED',
            'RECORDED',
            'ALL'
        ];
    }

    public static bizziFilterList(): string[] {
        return [
            'DRAFT',
            'APPROVED',
            'REJECTED',
            // 'CANCELED',
            'ALL'
        ];
    }
}

export const EPO_STATUS = EpoStatus;

export const BUDGET_REQUEST_TYPE = {
    ACCRUAL_TRANSFER: 'ACCRUAL_TRANSFER',
    HIGHER_BUDGET: 'HIGHER_BUDGET',
    COST_TRANSFER: 'COST_TRANSFER',
    ADVANCED_FROM_NEXT_YEAR: 'ADVANCED_FROM_NEXT_YEAR',
}

const budgetRequestTypeMap = {};
budgetRequestTypeMap[BUDGET_REQUEST_TYPE.ACCRUAL_TRANSFER] = 'Accrual Transfer';
budgetRequestTypeMap[BUDGET_REQUEST_TYPE.HIGHER_BUDGET] = 'Higher Budget';
budgetRequestTypeMap[BUDGET_REQUEST_TYPE.COST_TRANSFER] = 'Cost Transfer';
budgetRequestTypeMap[BUDGET_REQUEST_TYPE.ADVANCED_FROM_NEXT_YEAR] = 'Advance from next year\'s budget';
export const BUDGET_REQUEST_TYPE_MAP = budgetRequestTypeMap;

const statusMap = {};
statusMap[EPO_STATUS.DRAFT] = 'Nháp';
statusMap[EPO_STATUS.WAITING] = 'Đang chờ xét duyệt';
statusMap[EPO_STATUS.APPROVED] = 'Đã được duyệt';
statusMap[EPO_STATUS.REJECTED] = 'Đã bị từ chối';
statusMap[EPO_STATUS.HALF_DONE] = 'Hoàn thành một nửa';
statusMap[EPO_STATUS.CLOSED] = 'Đã đóng';
// statusMap[EPO_STATUS.CANCELED] = 'Đã hủy';
statusMap[EPO_STATUS.RECORDED] = 'Chấp nhận';
statusMap['ALL'] = 'Tất cả';

const paidStatusMap = {};
paidStatusMap[EPO_STATUS.PAID] = 'Đã Thanh Toán';
paidStatusMap[EPO_STATUS.UNPAID] = 'Chưa Thanh Toán';
paidStatusMap['ALL'] = 'Tất cả';

export const STATUS_MAP = statusMap;
export const PAID_STATUS_MAP = paidStatusMap;

export const PO_DETAIL_TYPE = {
    CHARGEABLE: 'CHARGEABLE',
    NONE_CHARGEABLE: 'NONE_CHARGEABLE'
};

export const AUTH_CONSTANTS = {
    TOKEN: 'epo_token',
    REFRESH_TOKEN: 'epo_refresh_token',
    ROLES: 'epo_roles',
    EPO_USER_ID: 'epo_user_id',
    EPO_USERNAME: 'epo_username',
    TOKEN_EXPIRED: 'token_expired',
    EPO_USER_FULLNAME: 'epo_userFullName',
    LANGUAGE: 'epo_language',
};

export const COMPONENT_ROUTE = {
    SYSTEM_CONFIG: 'system-config',
    SYSTEM_CONFIG_PATH: {
        ROLE_CONFIG: 'role',
        IMPORT_CSV: 'import-csv',
        EXPORT_CSV: 'export-csv',
        BIZZI_LOG: 'bizzi-monitor',
        INTEGRATION_LOG: 'log-monitor',
        EMAIL_SETTINGS: 'email-settings'
    },
    INTERNAL: 'internal',
    INTERNAL_PATH: {
        ORDER_MANAGEMENT: 'order-management',
        BUDGET_CONTROL: 'budget-control',
        BUDGET_CONTROL_DETAIL: 'budget-detail',
        BUDGET_CONTROL_NEW: 'budget-request-new',
        CREATE_ORDER: 'create-order',
        ORDER_DETAIL: 'order-detail',
        INTERNAL_REPORT: 'internal-report',
        INTERNAL_REPORT_PATH: {
            REPORT_AGGREGATED_BALANCE: 'aggregated-balance',
            REPORT_DETAIL_BALANCE: 'detail-balance',
            BUDGET_REQUEST_REPORT: 'budget-request',
            REPORT_PO_AGGREGATE: 'po-aggregate',
            REPORT_PO_BALANCE: 'po-balance'
        },
        ADVANCE: 'advance',
        CREATE_AD: 'create-ad',
        AD_DETAIL: 'ad-detail',

        EXPENSE: 'expense',
        CREATE_EXPENSE: 'create-expense',
        EXPENSE_DETAIL: 'expense-detail',

        CONTRACT: 'contract',
        CREATE_CONTRACT: 'create-contract',
        CONTRACT_DETAIL: 'contract-detail',

        BUDGET: 'budget'
    },

    OSS_MANAGEMENT: 'oss-management',
    OSS_MANAGEMENT_PATH: {
        ADVANCE: 'advance',
        CREATE_AD: 'create-ad',
        AD_DETAIL: 'ad-detail',
        ADVANCE_LIMIT: 'ad-limit',
        EXPENSE_ORIGINAL: 'expense',
        CREATE_EXPENSE_ORIGINAL: 'create-expense',
        EXPENSE_DETAIL_ORIGINAL: 'expense-detail',
        EXPENSE_SCAN_CONVERT: 'expense-convert',
        DOCUMENT_CATEGORY: 'document-category',
        LIMIT_REVISE: 'limit-revise',
        CREATE_LIMIT_REVISE: 'create-limit-revise',
        DETAIL_LIMIT_REVISE: 'detail-limit-revise',
        BANKTRANSFER: 'bank-transfer',
        REPORT_RECONCILE: 'reconcile',
        REPORT_OSS_EX_INFO: 'oss-ex-info-report',
        REPORT_OSS_ADVANCE_LIMIT: 'oss-advance-limit-report',
        REPORT_OSS_PAYMENT_LIMIT: 'oss-advance-payment-report',
        OSS_REPORT: 'oss-report',
        CREATE_BANK_TRANSFER: 'create-bank-transfer',
        BANK_TRANSFER_DETAIL: 'bank-transfer-detail'
    },

    INVOICE: 'invoice',
    INVOICE_PATH: {
        LIST_INVOICE: 'list',
        CREATE_INVOICE: 'new',
        REVENUE_REPORT: 'revenue-report',
        SHARE_REVENUE: 'share-revenue'
    },

    REVENUE_SHARING: 'revenue-sharing',

    ELEARNING: 'elearning',
    ELEARNING_PATH: {
        LIST: 'list'
    },

    SOA_EMAIL: 'soa-email',

    BIZZI: 'bizzi',
};

export const ROUTE_CONTANTS = {
    LANDING_PAGE: '/talentnet/dashboard',
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    NOT_FOUND: '/talentnet/not-found',
    CHANGE_PASSWORD: '/auth/login',
    INTERNAL: {
        PURCHASE_ORDER: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.ORDER_MANAGEMENT}`,
        CREATE_ORDER: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.ORDER_MANAGEMENT}/${COMPONENT_ROUTE.INTERNAL_PATH.CREATE_ORDER}`,
        ORDER_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.ORDER_MANAGEMENT}/${COMPONENT_ROUTE.INTERNAL_PATH.ORDER_DETAIL}`,
        BUDGET: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.BUDGET}`,
        BUDGET_CONTROL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.BUDGET_CONTROL}`,
        BUDGET_CONTROL_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.BUDGET_CONTROL}/${COMPONENT_ROUTE.INTERNAL_PATH.BUDGET_CONTROL_DETAIL}`,
        BUDGET_CONTROL_NEW: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.BUDGET_CONTROL}/${COMPONENT_ROUTE.INTERNAL_PATH.BUDGET_CONTROL_NEW}`,
        REPORT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT}`,
        REPORT_AGGREGATED_BALANCE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT_PATH.REPORT_AGGREGATED_BALANCE}`,
        REPORT_DETAIL_BALANCE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT_PATH.REPORT_DETAIL_BALANCE}`,
        REPORT_BUDGET_REQUEST: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT_PATH.BUDGET_REQUEST_REPORT}`,
        REPORT_PO_AGGREGATE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT_PATH.REPORT_PO_AGGREGATE}`,
        REPORT_PO_BALANCE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT}/${COMPONENT_ROUTE.INTERNAL_PATH.INTERNAL_REPORT_PATH.REPORT_PO_BALANCE}`,
        ADVANCE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.ADVANCE}`,
        CREATE_AD: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.ADVANCE}/${COMPONENT_ROUTE.INTERNAL_PATH.CREATE_AD}`,
        AD_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.ADVANCE}/${COMPONENT_ROUTE.INTERNAL_PATH.AD_DETAIL}`,
        EXPENSE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.EXPENSE}`,
        CREATE_EXPENSE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.EXPENSE}/${COMPONENT_ROUTE.INTERNAL_PATH.CREATE_EXPENSE}`,
        EXPENSE_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.EXPENSE}/${COMPONENT_ROUTE.INTERNAL_PATH.EXPENSE_DETAIL}`,
        CONTRACT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.CONTRACT}`,
        CREATE_CONTRACT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.CONTRACT}/${COMPONENT_ROUTE.INTERNAL_PATH.CREATE_CONTRACT}`,
        CONTRACT_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.INTERNAL_PATH.CONTRACT}/${COMPONENT_ROUTE.INTERNAL_PATH.CONTRACT_DETAIL}`,
        BANK_TRANSFER: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANKTRANSFER}`,
        CREATE_BANK_TRANSFER: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANKTRANSFER}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.CREATE_BANK_TRANSFER}`,
        BANK_TRANSFER_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INTERNAL}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANKTRANSFER}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANK_TRANSFER_DETAIL}`,
    },
    SYSTEM_CONFIG: {
        ROLE_CONFIG: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.ROLE_CONFIG}`,
        IMPORT_CSV: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.IMPORT_CSV}`,
        EXPORT_CSV: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.EXPORT_CSV}`,
        BIZZI_LOG: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.BIZZI_LOG}`,
        INTEGRATION_LOG: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.INTEGRATION_LOG}`,
        EMAIL_SETTINGS: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SYSTEM_CONFIG}/${COMPONENT_ROUTE.SYSTEM_CONFIG_PATH.EMAIL_SETTINGS}`
    },
    OSS_MANAGEMENT: {
        ADVANCE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.ADVANCE}`,
        ADVANCE_LIMIT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.ADVANCE_LIMIT}`,
        CREATE_AD: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.ADVANCE}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.CREATE_AD}`,
        AD_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.ADVANCE}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.AD_DETAIL}`,
        EXPENSE_ORIGINAL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.EXPENSE_ORIGINAL}`,
        EXPENSE_DETAIL_ORIGINAL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.EXPENSE_ORIGINAL}/:exType/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.EXPENSE_DETAIL_ORIGINAL}`,
        DOCUMENT_CATEGORY: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.DOCUMENT_CATEGORY}`,
        LIMIT_REVISE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.LIMIT_REVISE}`,
        CREATE_LIMIT_REVISE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.LIMIT_REVISE}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.CREATE_LIMIT_REVISE}`,
        DETAIL_LIMIT_REVISE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.LIMIT_REVISE}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.DETAIL_LIMIT_REVISE}`,
        BANK_TRANSFER: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANKTRANSFER}`,
        CREATE_BANK_TRANSFER: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANKTRANSFER}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.CREATE_BANK_TRANSFER}`,
        BANK_TRANSFER_DETAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANKTRANSFER}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.BANK_TRANSFER_DETAIL}`,
        REPORT_RECONCILE_OSS: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.OSS_REPORT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.REPORT_RECONCILE}`,
        REPORT_OSS_EX_INFO: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.OSS_REPORT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.REPORT_OSS_EX_INFO}`,
        REPORT_OSS_ADVANCE_LIMIT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.OSS_REPORT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.REPORT_OSS_ADVANCE_LIMIT}`,
        REPORT_OSS_PAYMENT_LIMIT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.OSS_MANAGEMENT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.OSS_REPORT}/${COMPONENT_ROUTE.OSS_MANAGEMENT_PATH.REPORT_OSS_PAYMENT_LIMIT}`,
    },
    INVOICE: {
        ROOT: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INVOICE}`,
        INVOICE_LIST: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INVOICE}/${COMPONENT_ROUTE.INVOICE_PATH.LIST_INVOICE}`,
        CREATE_INVOICE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INVOICE}/${COMPONENT_ROUTE.INVOICE_PATH.CREATE_INVOICE}`,
        SHARE_REVENUE: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.INVOICE}/${COMPONENT_ROUTE.INVOICE_PATH.SHARE_REVENUE}`,
    },
    REVENUE_SHARING: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.REVENUE_SHARING}`,
    SOA_EMAIL: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.SOA_EMAIL}`,
    ELEARNING: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.ELEARNING}/${COMPONENT_ROUTE.ELEARNING_PATH.LIST}`,
    BIZZI: `/${TALENTNET_ROOT_ROUTE}/${COMPONENT_ROUTE.BIZZI}`,
};

export const IMPORT_EXPORT = {
    LIST_TYPE: [
        { name: 'Ngân sách', urlImport: API_URL.IMPORT_CSV.IMPORT_BUDGET, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_BUDGET, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_BUDGET },
        { name: 'Phòng ban tài trợ', urlImport: API_URL.IMPORT_CSV.IMPORT_SPONSOR_DEPART, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_SPONSOR_DEPART, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_SPONSOR_DEPART },
        { name: 'Phòng ban điều khiển', urlImport: API_URL.IMPORT_CSV.IMPORT_CONTROL_DEPART, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_CONTROL_DEPART, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_CONTROL_DEPART },
        { name: 'Danh sách người duyệt', urlImport: API_URL.IMPORT_CSV.IMPORT_APPROVER, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_APPROVER, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_APPROVER },
        { name: 'Khách hàng', urlImport: API_URL.IMPORT_CSV.IMPORT_CUSTOMER, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_CUSTOMER, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_CUSTOMER },
        { name: 'Nhà cung cấp', urlImport: API_URL.IMPORT_CSV.IMPORT_SUPPLIER, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_SUPPLIER, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_SUPPLIER },
        { name: 'Nhân viên Talentnet', urlImport: API_URL.IMPORT_CSV.IMPORT_USER, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_USER, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_USER },
        { name: 'Nhân viên OSS', urlImport: API_URL.IMPORT_CSV.IMPORT_USER_OSS, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_USER_OSS, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_USER_OSS },
        { name: 'Bộ phận kế toán', urlImport: API_URL.IMPORT_CSV.IMPORT_ACCOUNTANT_DEPART, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_ACCOUNTANT_DEPART, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_ACCOUNTANT_DEPART },
        { name: 'Danh mục vật tư', urlImport: API_URL.IMPORT_CSV.IMPORT_MATERIAL, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_MATERIAL, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_MATERIAL },
        { name: 'Danh mục tiền tệ', urlImport: API_URL.IMPORT_CSV.IMPORT_CURRENCY, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_CURRENCY, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_CURRENCY },
        { name: 'Danh mục thuế', urlImport: API_URL.IMPORT_CSV.IMPORT_TAX, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_TAX, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_TAX },
        { name: 'Khoản mục ngân sách', urlImport: API_URL.IMPORT_CSV.IMPORT_BUDGET_ITEM, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_BUDGET_ITEM, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_BUDGET_ITEM },
        { name: 'Chi tiết khoản mục ngân sách', urlImport: API_URL.IMPORT_CSV.IMPORT_BUDGET_ITEM_DETAIL, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_BUDGET_ITEM_DETAIL, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_BUDGET_ITEM_DETAIL },
        { name: 'Danh mục ngân hàng', urlImport: API_URL.IMPORT_CSV.IMPORT_OSS_BANK, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_OSS_BANK, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_OSS_BANK },
        { name: 'Danh mục loại chi phí - OSS', urlImport: API_URL.IMPORT_CSV.IMPORT_OSS_COSTS, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_OSS_COSTS, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_OSS_COSTS },
        { name: 'Danh mục bộ phận - OSS', urlImport: API_URL.IMPORT_CSV.IMPORT_OSS_DEPARTMENT, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_OSS_DEPARTMENT, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_OSS_DEPARTMENT },
        { name: 'Danh mục đối tượng thanh toán - OSS', urlImport: API_URL.IMPORT_CSV.IMPORT_OSS_PAYMENT_SUBJECT, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_OSS_PAYMENT_SUBJECT, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_OSS_PAYMENT_SUBJECT },
        { name: 'Giới hạn tạm ứng', urlImport: API_URL.IMPORT_CSV.IMPORT_ADVANCE_LIMIT, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_ADVANCE_LIMIT, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_ADVANCE_LIMIT },
        { name: 'Danh mục ngày nghỉ trong năm', urlImport: API_URL.IMPORT_CSV.IMPORT_HOLIDAY, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_HOLIDAY, urlExportExcel: null },
        { name: 'Khoản mục chi phí', urlImport: API_URL.IMPORT_CSV.IMPORT_EXPENSE_ITEM, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_EXPENSE_ITEM, urlExportExcel: API_URL.EXPORT_EXCEL.EXPORT_EXPENSE_ITEM },
        { name: 'Địa điểm', urlImport: API_URL.IMPORT_CSV.IMPORT_LOCATION, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_LOCATION },
        { name: 'Đơn vị', urlImport: API_URL.IMPORT_CSV.IMPORT_UNIT_OF_MEASURE, urlExportCsv: API_URL.EXPORT_CSV.EXPORT_UNIT_OF_MEASURE },
        { name: 'Log', urlExportCsv: API_URL.EXPORT_CSV.EXPORT_LOG, parameters: [{name: 'startTimestamp', formatFnc: date => date.getTime()}, {name: 'endTimestamp', formatFnc: date => date.getTime()}]},
        { name: 'Log lỗi', urlExportCsv: API_URL.EXPORT_CSV.EXPORT_EXCEPTION_LOG, parameters: [{name: 'startTimestamp', formatFnc: date => date.getTime()}, {name: 'endTimestamp', formatFnc: date => date.getTime()}]}
    ].sort((a, b) => a.name.localeCompare(b.name))
};

export const SEARCH_PARAMS_AD_CONSTANT = {
    SEARCH_TEXT: 'searchText',
    PAGE: 'page',
    SIZE: 'size',
    FROM_DATE: 'fromDate',
    TO_DATE: 'toDate',
    STATUS: 'status',
    AD_ID: 'adId',
    CREATED_BY: 'createdBy',
    BUDGET_CODE: 'budgetCode',
    AD_OSS_TYPE: 'adType',
    DESCRIPTION: 'description',
    PO_ID: 'poId',
    PO_CREATED_BY: 'poCreatedBy',
    PO_DESCRIPTION: 'poDescription',
    SORT: 'sort'
};

export const SEARCH_PARAMS_CONSTANT = {
    PAGE: 'page',
    SIZE: 'size',
    FROM_DATE: 'fromDate',
    TO_DATE: 'toDate',
    STATUS: 'status',
    PAID_STATUS: 'paid',
    EX_TYPE: 'exType',
    CREATED_BY: 'createdBy',
    EX_ID: 'exId',
    PO_ID: 'poId',
    BUDGET_CODE: 'budgetCode',
    DESCRIPTION: 'description',
    SEARCHTEXT: 'searchText',
    SUPPLIER_ID: 'supplierId',
    SEARCH_TEXT: 'searchText',
    PO_SEARCH_TEXT: 'poSearchText',
    AD_SEARCH_TEXT: 'adSearchText',
};

export const TYPE_TAX = {
    VKCT: 'VKCT', // chua thuế
    V00: 'V00', // chua thuế
    V05: 'V05', // chua thuế
    V10: 'V10', // chưa thuế
    V7: 'V07', // chưa thuế
    V3_5: 'V3.5', // chưa thuế
    V05A: 'V05A', // bao gồm thuế
    V10A: 'V10A', // bao gồm thuế
    NO_TAX: 'NO_TAX', // chưa thuế
    priceIncludeTax(taxType: string) {
        return taxType === TYPE_TAX.V05A || taxType === TYPE_TAX.V10A;
    }
};

export const TYPE_OBJECT = {
    TLN: 'TLN',
    SUPPLIER: 'SUPPLIER',
    OSS: 'OSS'
};

export const PAYMENT_TYPE_OBJECT = {
    INTERNAL: [{ 'name': 'Nhân viên TLN', 'value': TYPE_OBJECT.TLN },
    { 'name': 'Nhà cung cấp', 'value': TYPE_OBJECT.SUPPLIER }],
    OSS: [{ 'name': 'Nhân viên TLN', 'value': TYPE_OBJECT.TLN },
    { 'name': 'Nhà cung cấp', 'value': TYPE_OBJECT.SUPPLIER },
    { 'name': 'Nhân viên OSS', 'value': TYPE_OBJECT.OSS }]
};

export const PAYMENT_TYPE = [{ 'name': 'Bank Transfer', 'value': 'TRANSFER' }, { 'name': 'Cash Transfer', 'value': 'CASH' }];
export const PAYMENT_TYPE_MAP = {
    TRANSFER: 'Bank Transfer',
    CASH: 'Cash Transfer',
};

export const SERVICE_FEE_TYPES = [{ 'name': 'Theo công thức', 'value': 'FORMULAR' }, { 'name': 'Không theo công thức', 'value': 'NO_FORMULAR' }];
export const SERVICE_FEE_TYPES_MAP = {
    FORMULAR: 'Theo công thức',
    NO_FORMULAR: 'Không theo công thức',
};

export const PERCENT_SERVICE_FEE_TYPES = [{ 'name': 'Tiền sau VAT', 'value': 'AFTER_TAX' }, { 'name': 'Tiền trước VAT', 'value': 'BEFORE_TAX' }];
export const PERCENT_SERVICE_FEE_TYPES_MAP = {
    AFTER_TAX: 'Tiền sau VAT',
    BEFORE_TAX: 'Tiền trước VAT',
};

export const CLIENT_TYPES = [{ 'name': 'Nhân viên', 'value': 'EMPLOYEE' }, { 'name': 'Nhà cung cấp', 'value': 'SUPPLIER' }];

export const REVISE_TYPES = [{ 'name': 'Higher Limit', 'value': 'HIGHER_LIMIT' }, { 'name': 'Client Confirmed', 'value': 'CLIENT_CONFIRMED' }];

export const COST_SHARING_TARGETS = [{ 'name': 'Nhân viên', 'value': 'EMPLOYEE' }, { 'name': 'Bộ phận', 'value': 'DEPARTMENT' }];

export const PURCHASE_ORDER_TYPES = ['NONE_CHARGEABLE', 'CHARGEABLE'];

export const OSS_EXPENSE_TYPES = [
    { name: 'Gốc', value: 'ORIGINAL' },
    { name: 'Scan', value: 'SCAN' },
    { name: 'Chuyển đổi', value: 'CONVERT' },
];

export const BANK_NAME = [
    { name: 'Vietcombank', 'account': '007.100.4287366', 'desc': 'Vietcombank_007.100.4287366'},
    { name: 'HSBC', 'account': '001-108802-002', 'desc': 'HSBC_001-108802-002'},
    { name: 'Vietinbank', 'account': '116000179750', 'desc': 'Vietinbank_116000179750'}
]
