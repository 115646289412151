import { Component, OnInit } from '@angular/core';
import { NbAuthService } from './talentnet-pages/auth/public_api';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  constructor(private authService: NbAuthService) {
    authService.registerSessionEvent();
  }

  ngOnInit(): void {
  }
}
