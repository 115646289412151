import { MessageService } from './../../../common/services/message.service';
import { EpoLoginService } from './../../../common/services/epo-login.service';
import { ROUTE_CONTANTS } from './../../../common/constants/app.constants';
import { AuthHelper } from './../../../common/helpers/auth.helper';
import { ServiceHelper } from './../../../common/helpers/service.helper';
import { Inject, Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NbAuthService } from '../auth.service';
import { NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '../../auth.options';
import { AUTH_CONSTANTS } from '../../../common/constants/app.constants';
import { Router } from '@angular/router';
import { getMessage } from '../../../common/constants/error_code.constants';
import { LoginModel } from '../../../common/models/login.model';

@Injectable()
export class NbAuthJWTInterceptor implements HttpInterceptor {

  constructor(private injector: Injector,
    private router: Router,
    private messageService: MessageService,
    private loginService: NbAuthService,
    @Inject(NB_AUTH_TOKEN_INTERCEPTOR_FILTER) protected filter) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (ServiceHelper.isObjectNotEmpty(localStorage.getItem(AUTH_CONSTANTS.TOKEN))) {
      const JWT = `Bearer ${localStorage.getItem(AUTH_CONSTANTS.TOKEN)}`;
      req = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '*',
          Authorization: JWT,
        },
      });
      return next.handle(req).pipe(
        catchError(
          (err) => {
            if (err.status === 401 || (err.status === 500 && err.error.message === 'TOKEN_EXPIRE')) {
              this.loginService.requestRelogin();
              // Send the refreshtoken
              // this.loginService.refreshToken().subscribe(res => {
              //   AuthHelper.clearAllStorage();
              //   if (res.status === 200) {
              //     this.loginService.setLoginDataResponse(res.body);
              //     // Resend the api
              //     return next.handle(this.prepareRequestBeforeReSending(req));
              //   }

              // });
            } else if (err.status === 403) {
              ServiceHelper.errorPopup('Bạn không có quyền thực hiện việc này');
              this.messageService.publishEvent({
                code: err.status,
                message: 'Bạn không có quyền thực hiện việc này'
              });
              this.router.navigateByUrl(ROUTE_CONTANTS.LOGIN);
            } else if (err.status === 400) {
              const errorKey = ServiceHelper.isObjectNotEmpty(err.error)
                && ServiceHelper.isObjectNotEmpty(err.error.errorKey) ? err.error.errorKey : null;
              const errorMessage = err.error ? err.error.message : err.error;
              const message = ServiceHelper.isObjectNotEmpty(errorKey) ? getMessage(errorKey) : errorMessage;
              this.messageService.publishEvent({
                code: err.status,
                message: message
              });
              ServiceHelper.errorPopup(message);
            } else if (err.status === 500) {
              ServiceHelper.errorPopup(err.error.message ? err.error.message : 'Lỗi từ hệ thống, vui lòng thử lại sau');
              this.messageService.publishEvent({
                code: err.status,
                message: 'Lỗi hệ thống'
              });
            } else if (err.status === 404) {
              // const message = !err || !err.error ? 'Không tìm thấy' : (getMessage(err.error.errorKey) ? getMessage(err.error.errorKey) : err.error.message);
              const errorKey = ServiceHelper.isObjectNotEmpty(err.error)
                                && ServiceHelper.isObjectNotEmpty(err.error.errorKey) ? err.error.errorKey : null;
              const errorMessage = err.error ? err.error.message : (err.error || 'Không tìm thấy');
              const message = ServiceHelper.isObjectNotEmpty(errorKey) ? getMessage(errorKey) : errorMessage;
              this.messageService.publishEvent({
                code: err.status,
                message: message
              });
              ServiceHelper.errorPopup(message);
            }
            return throwError(err);
          }
        )
      );
    } else {
      return next.handle(req).pipe(
        catchError(
          (err) => {
            if (err.status === 401) {
              this.loginService.forceLogout();
              ServiceHelper.errorPopup('Vui lòng đăng nhập để sử dụng dịch vụ Eclaim');
            }
            return throwError(err);
          }
        )
      );
    }
  }

  // private prepareRequestBeforeReSending(req: HttpRequest<any>) {
  //   let header = req.headers;
  //   header = header.set('Authorization', `Bearer ${localStorage.getItem(AUTH_CONSTANTS.TOKEN)}`);
  //   header = header.set('Accept', 'application/json');
  //   header = header.set('Content-Type', 'application/json');
  //   return req.clone({
  //     headers: header
  //   });
  // }

  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }

}
