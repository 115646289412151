import { LoginModel } from './../models/login.model';
import { AUTH_CONSTANTS } from '../constants/app.constants';
import { ServiceHelper } from '../helpers/service.helper';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../constants/api.constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class EpoLoginService {
    helper: JwtHelperService;
    roles: Array<string> = [];

    constructor(
        private http: HttpClient,
    ) {
        this.helper = new JwtHelperService()
    }


    public refreshToken(): Observable<any> {
        const token = localStorage.getItem(AUTH_CONSTANTS.REFRESH_TOKEN);
        return this.http.post<any>(API_URL.REFRESH_TOKEN, { refreshToken: token });
    }

    public loadRoleFromToken(): void {
        const token = localStorage.getItem(AUTH_CONSTANTS.TOKEN);
        if (ServiceHelper.isObjectEmpty(token)) {
            console.error('Unable to parse login response');
            return;
        }
        const decodedToken = this.helper.decodeToken(token);
        this.roles = decodedToken.listRoles;
    }

    public setLoginDataResponse(loginModel: LoginModel): void {
        if (ServiceHelper.isObjectNotEmpty(loginModel)) {
            const decodedToken = this.helper.decodeToken(loginModel.data.token);
            localStorage.setItem(AUTH_CONSTANTS.REFRESH_TOKEN, loginModel.data.refreshToken);
            localStorage.setItem(AUTH_CONSTANTS.EPO_USER_ID, loginModel.data.userId);
            localStorage.setItem(AUTH_CONSTANTS.EPO_USERNAME, loginModel.data.email);
            localStorage.setItem(AUTH_CONSTANTS.TOKEN_EXPIRED, (+decodedToken.exp * 1000).toString());
            localStorage.setItem(AUTH_CONSTANTS.EPO_USER_FULLNAME, loginModel.data.userFullName);
            return;
        }
        console.error('Unable to parse login response');
    }

    public getUserRoles(): string[] {
        return this.roles || [];
    }

    public hasRole(expectedRole: string): boolean {
        const roles: string[] = this.roles;
        for (let i = 0; i < roles.length; i++) {
            if (roles[i].toLowerCase() === expectedRole.toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    public getCurrentUserId(): string {
        return localStorage.getItem(AUTH_CONSTANTS.EPO_USER_ID);
    }
}