import { Component} from '@angular/core';
import { AppLanguageService } from '../../services/app-language.service';

@Component({
  selector: 'ngx-app-language',
  templateUrl: './app-language.component.html',
  styleUrls: ['./app-language.component.scss']
})
export class AppLanguageComponent {
  selectedLanguage: string = 'en';
  constructor(private appLanguageService: AppLanguageService) {
    this.selectedLanguage = this.appLanguageService.getLanguage();
  }

  onLanguageSelect(language) {
    this.appLanguageService.setLanguage(language);
    this.appLanguageService.use(language);
  }

}
