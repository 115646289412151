import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { NbAuthModule } from './talentnet-pages/auth/auth.module';
import { NbPasswordAuthStrategy } from './talentnet-pages/auth/strategies/password/password-strategy';
import { NbSecurityModule } from '@nebular/security';
import { AppLanguageModule } from './talentnet-pages/common/components/app-language/app-language.module';
import { API_URL } from './talentnet-pages/common/constants/api.constants';
import { NbAuthJWTInterceptor } from './talentnet-pages/auth/services/interceptors/jwt-interceptor';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { registerLocaleData } from '@angular/common';
import localeVi from '@angular/common/locales/vi';
registerLocaleData(localeVi);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AppLanguageModule,
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    CoreModule.forRoot(),
    NbSecurityModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          token: {
            key: 'jwttoken', // this parameter tells where to look for the token
          },
          baseEndpoint: environment.apiBaseUrl + environment.defaultVersion,
          login: {
            endpoint: API_URL.LOGIN,
          },
        }),
      ],
      forms: {},
    }),
    NbDateFnsDateModule.forRoot({
      format: 'dd/MM/yyyy'
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'vi' },
  ],
})
export class AppModule {
}
