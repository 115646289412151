import { NgModule } from '@angular/core';
import { AppLanguageComponent } from './app-language.component';
import { NbSelectModule } from '@nebular/theme';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?dummy=' + Math.random().toString());
}

@NgModule({
  imports: [
    NbSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [AppLanguageComponent],
  exports: [AppLanguageComponent, TranslateModule]
})
export class AppLanguageModule {
}
