import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from './talentnet-pages/auth/public_api';
import { AuthenticateGuard } from './talentnet-pages/common/guards/authenticate.guard';
import { UnAuthenticateGuard } from './talentnet-pages/common/guards/unauthenticate.guard';
import { TALENTNET_ROOT_ROUTE } from './talentnet-pages/common/constants/app.constants';

const routes: Routes = [
  {
    path: TALENTNET_ROOT_ROUTE,
    loadChildren: () => import('./talentnet-pages/talentnet-pages.module')
      .then(m => m.TalentnetPagesModule),
    canActivate: [AuthenticateGuard]
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: NbLoginComponent
      },
      {
        path: 'login',
        component: NbLoginComponent,
        canActivate: [UnAuthenticateGuard]
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
    ],
  },
  { path: '', redirectTo: 'talentnet', pathMatch: 'full' },
  { path: '**', redirectTo: 'talentnet' },
];

const config: ExtraOptions = {
    useHash: false,
    relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
