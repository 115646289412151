import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class MessageService {

  constructor() { }

  private subject = new Subject<ResponseDataEvent>();

  publishEvent(message: ResponseDataEvent) {
    this.subject.next(message);
  }

  listenEvent() {
    return this.subject.asObservable();
  }
}

export class ResponseDataEvent {
  code: number;
  message: string;
}
