<h1 id="title" class="title">
    <img class="logo-icon" src="assets/images/eclaim-logo.png" alt="logo" style="width: 160px; height: 40px; margin-top: 30px; margin-bottom: 30px;">
</h1>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">
    <div class="form-control-group">
        <label class="label" for="input-email">Tên tài khoản:</label>
        <input nbInput fullWidth [(ngModel)]="user.username" #email="ngModel" name="email" id="input-email"
            pattern=".+" placeholder="Tên tài khoản" fieldSize="large" autofocus
            [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
            [required]="getConfigValue('forms.validation.email.required')"
            [attr.aria-invalid]="email.invalid && email.touched ? true : null">
        <ng-container *ngIf="email.invalid && email.touched">
            <p class="caption status-danger" *ngIf="email.errors?.required">
                Vui lòng điền tên tài khoản!
            </p>
            <p class="caption status-danger" *ngIf="email.errors?.pattern">
                Tên tài khoản không hợp lệ
            </p>
        </ng-container>
    </div>
    <div class="form-control-group">
        <span class="label-with-link">
            <label class="label" for="input-password">Mật khẩu:</label>
            <!-- <a class="forgot-password caption-2" routerLink="../request-password">Quên mật khẩu?</a> -->
        </span>
        <input nbInput fullWidth [(ngModel)]="user.password" #password="ngModel" name="password" type="password"
            id="input-password" placeholder="Mật khẩu" fieldSize="large"
            [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
            [required]="getConfigValue('forms.validation.password.required')"
            [minlength]="getConfigValue('forms.validation.password.minLength')"
            [maxlength]="getConfigValue('forms.validation.password.maxLength')"
            [attr.aria-invalid]="password.invalid && password.touched ? true : null">
        <ng-container *ngIf="password.invalid && password.touched ">
            <p class="caption status-danger" *ngIf="password.errors?.required">
                Vui lòng điền mật khẩu!
            </p>
            <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
                Mật khẩu không hợp lệ
            </p>
        </ng-container>
    </div>
    <p *ngIf="loginFailed" class="caption status-danger" style="font-size: medium;">
        Tên đăng nhập hoặc mật khẩu không đúng
    </p>
    <button nbButton class="btn-login" fullWidth size="large" [nbSpinner]="submitted" nbSpinnerStatus="control" [disabled]="submitted">
        Đăng nhập
    </button>
</form>