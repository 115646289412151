import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONSTANTS } from '../constants/app.constants';


@Injectable({
    providedIn: 'root',
})
export class AppLanguageService extends TranslateService {
    private supportedLanguages: string[] = ['vi'];
    private language: string;

    public getLanguage(): string {
        const language = localStorage.getItem(APP_CONSTANTS.LANGUAGE);
        return this.isSupportedLanguage(language) ? language : 'vi';
    }

    private isSupportedLanguage(language: string): boolean {
        return this.supportedLanguages.includes(language);
    }

    public setLanguage(language): void {
        if (this.isSupportedLanguage(language)) {
            localStorage.setItem(APP_CONSTANTS.LANGUAGE, language);
            return;
        }
        console.error('Language ' + language + ' is not supported, we will set english as default');
    }

}