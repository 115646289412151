import { AUTH_CONSTANTS } from '../constants/app.constants';

export class AuthHelper {
    public static getCurrentUserId() {
      return localStorage.getItem(AUTH_CONSTANTS.EPO_USER_ID);
    }

    public static clearAllStorage(): void {
        const authConstants = AUTH_CONSTANTS;
        for (var i in authConstants) {
            if (authConstants.hasOwnProperty(i)) {
                localStorage.removeItem(authConstants[i]);
            }
        }
    }
}