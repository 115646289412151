import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AUTH_CONSTANTS, ROUTE_CONTANTS } from '../constants/app.constants';
import { Injectable } from '@angular/core';
import { ServiceHelper } from '../helpers/service.helper';

@Injectable({
    providedIn: 'root'
})
export class UnAuthenticateGuard implements CanActivate {
    constructor(public router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (ServiceHelper.isObjectEmpty(localStorage.getItem(AUTH_CONSTANTS.TOKEN))) {
            return of(true);
        }
        this.router.navigate([ROUTE_CONTANTS.LANDING_PAGE]);
        return of(false);
    }
}
